import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

// redux
import { fetchJobs } from "../../redux/slices/jobs"
import ApplicationReview from "../../components/DashboardPage/DashboardAppliedJobs/ApplicationReview"

// const BREAKPOINT = 1060

const AppliedJobPage = ({ data }) => {
  const urlParams = new URLSearchParams(
    typeof window !== "undefined" && window.location.search
  )

  const checkSearchPage = () => {
    return urlParams.get("id") !== null
  }

  const { account } = useSelector((state) => ({
    account: state.session.account,
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    if (account) {
      dispatch(fetchJobs())
    }
  }, [account])

  return (
    <Layout>
      <SEO title="Application Review" />
      {checkSearchPage() && (
        <ApplicationReview id={parseInt(urlParams.get("id"))} data={data} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulJobOpening(sort: { order: ASC, fields: createdAt }) {
      nodes {
        id
        backendId
        nama
        lokasi
        slug
        tags {
          tags
        }
        employmentType
        deskripsi {
          deskripsi
        }
        perusahaan {
          logo {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          nama
          kategori
        }
        jobCategory {
          kategori
        }
      }
    }
  }
`

export default AppliedJobPage
