import styled from "styled-components"

export const Styles = styled.div`
  margin-bottom: 64px;
  font-family: "DM Sans";

  #gap-1 {
    height: 96px;
  }

  .greetings {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .set-margin {
      margin-top: 0px;
    }

    .btn-edit {
      // width: 160px;
      display: flex;
      align-items: center;
      font-family: "DM Sans";
      margin-bottom: 8px;

      .editicon {
        margin-right: 5px;
      }
    }

    .edit-prof {
      display: flex;
      margin-bottom: 8px;
      p {
        margin-right: 8px;
      }
    }

    @media screen and (max-width: 768px) {
      // flex-direction: column;
      align-items: baseline;

      h1 {
        max-width: 50vw;
      }
    }

    @media screen and (max-width: 576px) {
      .btn-edit {
        width: 135px;
        font-size: 95%;
      }
      h1 {
        font-size: 1.75rem;
      }
    }

    @media screen and (max-width: 375px) {
      flex-direction: column;
      h1 {
        max-width: 100%;
      }
      .btn-edit {
        font-size: 95%;
        width: 140px;
      }

      .edit-prof {
        flex-direction: column;
      }
    }
  }

  .notverified {
    font-weight: bold;
    font-size: 14px;
    color: #eb3346;
  }
`
