import styled from "styled-components"

const ReviewCSS = styled.div`
  .tersiary {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: #ffcb15;
    padding: 0;
  }

  margin-top: 2.4rem;
  .application__wrapper {
    .header-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 2.5rem;
      .company-logo {
        margin-right: 1rem;
        width: 3.5rem;
        height: 3.5rem;
        background: #f3f3f3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        img {
          width: 2.75rem;
          height: 2.75rem;
        }
      }
      .title-section {
        h1,
        p {
          margin: 0;
        }
        .job-title {
          font-size: 1.5rem;
          margin-bottom: 0.25rem;
        }
        .job-company {
          font-size: 0.875rem;
        }
      }
    }
    .job-details {
      display: flex;
      .job-content {
        flex: 2;
        margin-right: 2.5rem;
        .job-status {
          .is-applied {
            display: flex;
            .checkmark {
              margin-right: 0.8rem;
              width: 1.75rem;
              height: auto;
              img {
                width: 100%;
                height: 100%;
              }
            }
            h2 {
              margin: 0;
              &.applied {
                color: #0acf83;
              }
            }
          }
        }
      }
      .job-application {
        flex: 1;
        h2,
        h4,
        p {
          color: #f3f3f3;
          margin: 0;
        }
        .title {
          font-size: 1.5rem;
          margin-bottom: 2.25rem;
        }
        .identity {
          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
          .form-attributes {
            font-weight: bold;
            font-style: normal;
            font-size: 1.1rem;
            margin-bottom: 0.5rem;
            span {
              margin-left: 1.5rem;
              font-size: 1rem;
              font-weight: 400;
              color: #ffcb15;
              cursor: pointer;
              &:hover {
                opacity: 0.8;
              }
            }
          }
          .field {
            font-style: normal;
            font-weight: normal;
          }
          .CV_Wrapper,
          .Portfolio_Wrapper {
            display: flex;
            align-items: unset;
            img {
              margin-right: 0.75rem;
            }
            a {
              margin-top: 0.25rem;
              text-decoration: underline;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
`

export default ReviewCSS
