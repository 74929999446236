import React from "react"
import { useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import { getAuthUser } from "../../redux/slices/session/selectors"
import withLogin from "../withLoggedIn"
import { Styles } from "./style"

import Button from "../Button"
import editicon from "./imgs/editicon.svg"

const IndexApplicationsDashboard = (props) => {
  const { account } = useSelector((state) => ({
    account: getAuthUser(state),
  }))

  return (
    <Styles>
      <div className="layout">
        <div id="gap-1" />
        <div className="greetings">
          {account ? (
            <h1 className="set-margin">Hi, {account.name}</h1>
          ) : (
            <h1>You are not logged in.</h1>
          )}
          <div className="edit-prof">
            {/* {account && !account.is_verified && (
              <p>
                <Button onClick={resendEmail} className="btn-edit">
                  Resend Email
                </Button>
              </p>
            )} */}
            <p>
              <Button className="secondary btn-edit" to="/dashboard/profile">
                <img src={editicon} alt="pencil" className="editicon" />
                <FormattedMessage id="jobfair.applied.yourApp.editProfile" />
              </Button>
            </p>
          </div>
        </div>
        {/* {account && !account.is_verified && (
          <div className="notverified">
            Please check your email and complete your profile to verify account!
            You will not be able to do anything unless your account is verified.
          </div>
        )} */}
        <div id="gap-1" />
        {props.children}
        <div id="gap" />
      </div>
    </Styles>
  )
}

export default withLogin(IndexApplicationsDashboard)
