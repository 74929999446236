import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Style from "./style"
import { navigate } from "gatsby"
import { FormattedMessage } from "react-intl"

// components
import ApplicationsDashboardPage from "../../../DashboardPage/index-applicationreview"
import DetailLowongan from "../../../DetailLowongan"

// redux
import { selectAppliedJobs } from "../../../../redux/slices/jobs"
import Button from "../../../Button"

const ApplicationReview = ({ id, data }) => {
  // TODO: Implement application(Formik)
  // TODO: Refactor
  const [backendJob, setBackendJob] = useState(null)
  const [contentfulJob, setContentfulJob] = useState(null)
  const appliedJobs = useSelector(selectAppliedJobs)
  const { account } = useSelector((state) => ({
    account: state.session.account,
  }))

  const contentfulJobs = data.allContentfulJobOpening.nodes

  useEffect(() => {
    if (appliedJobs.length > 0) {
      const job = appliedJobs.filter((element) => {
        return element.id === id
      })
      if (job.length > 0) {
        setBackendJob(job[0])
        setContentfulJob(
          contentfulJobs.filter((element) => {
            return job[0].openingId === element.backendId
          })[0]
        )
      } else {
        navigate("/")
      }
    }
  }, [appliedJobs])
  return (
    <ApplicationsDashboardPage>
      <Style>
        <Button to="/applications" className="nude tersiary">
          &lt; <FormattedMessage id="jobfair.applied.back" />
        </Button>
        {contentfulJob && backendJob ? (
          <DetailLowongan
            backendJob={backendJob}
            lowongan={contentfulJob}
            account={account}
            type="appliedjob"
          />
        ) : (
          <>Please wait...</>
        )}
      </Style>
    </ApplicationsDashboardPage>
  )
}

export default ApplicationReview
